import './PaquetesPage.css';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdVerifiedUser } from 'react-icons/md';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Button from '@material-ui/core/Button';

import Navbar from '../HomePage/Navbar/Navbar';
import Footer from '../HomePage/Footer/Footer';
import GalleryTemplate2 from '../RootTemplates/Template2/GalleryTemplate2/GalleryTemplate2';
import { userHeaders } from '../../Utils/headerSetter';
import DotLoading from '../../componentes/DotLoading/DotLoading';
import { IoIosArrowBack } from 'react-icons/io/index';
import { kindOfPrices } from '../../componentes/pasos/PasoPaquetes/ModalCreatePackage';
import { getCurrencyFromCountry } from '../../Utils/utilFunctions';
import { botonesContacto } from '../RootTemplates/Template2/ContactBox/ContactBox';
import { IoLogoWhatsapp } from 'react-icons/io';
import { HiChatAlt, HiOutlineChatAlt } from 'react-icons/hi';
import ModalContact from '../RootTemplates/Template2/ContactBox/ModalContact';
import { handleWhatsAppPackageContact } from '../../Utils/whatsAppContact';
import { Alert } from '@mui/material';
import PlanodiIcons from '../../Utils/PlanodiIcons';
import RichTextVisualizer from '../../componentes/RichText/RichTextVisualizer';

const BoxPaquetePage = ({ infoPaquete, infoAliado }) => {
  const { t } = useTranslation('global');
  const classes = botonesContacto();
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    whatsapp,
    nombre: nombreAliado,
    pais,
    telefono,
    email,
    webSite,
    instagram,
    facebook,
    youtube,
  } = infoAliado;
  const { kindOfPrice, name: nombrePaquete } = infoPaquete;

  return (
    <div className="paquetes-page-info-control">
      <ModalContact
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        aliado={nombreAliado}
        paisIdAliado={pais}
        phoneNumber={telefono}
        whatsAppNumber={whatsapp}
        email={email}
        handleWhatsAppContact={handleWhatsAppPackageContact}
        webSite={webSite}
        instagram={instagram}
        facebook={facebook}
        youtube={youtube}
        paquete={nombrePaquete}
        type={'package'}
      />
      {Boolean(infoPaquete.price) && (
        <div>
          <p className="">{t('packages.from')}</p>
          <div className="paquetes-page-info-control-price-div">
            <p className="paquetes-page-info-control-price">
              $
              {infoPaquete.price.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </p>
            <p className="paquetes-page-info-control-price-2">
              {getCurrencyFromCountry(1)}{' '}
              {kindOfPrice && kindOfPrice < 5 ? t(`packages.${kindOfPrices[kindOfPrice]}`) : null}
            </p>
          </div>
        </div>
      )}
      <Button
        className={Boolean(whatsapp) ? classes.whiteButton : classes.button}
        style={{ width: '100%' }}
        onClick={() => setModalOpen(true)}
      >
        {Boolean(whatsapp) ? (
          <HiOutlineChatAlt style={{ fontSize: '18px', marginRight: '10px' }} />
        ) : (
          <HiChatAlt style={{ fontSize: '18px', marginRight: '10px' }} />
        )}
        {t('temp2.contactInfo')}
      </Button>
      {Boolean(whatsapp) && (
        <Button
          className={classes.button}
          style={{ width: '100%', marginTop: '1rem' }}
          target="_blank"
          href={handleWhatsAppPackageContact(pais, whatsapp, nombreAliado, nombrePaquete)}
        >
          <IoLogoWhatsapp style={{ fontSize: '16px', marginRight: '15px' }} />{' '}
          {t('temp2.sendWhatsApp')}
        </Button>
      )}
    </div>
  );
};

export default function PaquetesPage() {
  const { t } = useTranslation('global');
  const { paqueteId } = useParams();

  const mobile = useMediaQuery('(max-width:580px)');
  const mobile2 = useMediaQuery('(max-width:960px)');

  const [loading, setLoading] = useState(true);
  const [activeImage, setActiveImage] = useState(0);
  const [activeScrollImage, setActiveScrollImage] = useState(1);
  const [images, setImages] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [infoAliado, setInfoAliado] = useState(null);
  const [infoPaquete, setInfoPaquete] = useState(null);

  const refFLexImages = useRef(null);
  const navigate = useNavigate();

  const handleScrollImage = () => {
    if (refFLexImages && refFLexImages.current) {
      setActiveScrollImage(
        Math.ceil(refFLexImages.current.scrollLeft / refFLexImages.current.clientWidth) + 1
      );
    }
  };

  const changeImage = (direccion) => {
    if (direccion > 0) {
      activeImage === images.length - 1 ? setActiveImage(0) : setActiveImage(activeImage + 1);
    }
    if (direccion < 0) {
      activeImage === 0 ? setActiveImage(images.length - 1) : setActiveImage(activeImage - 1);
    }
  };
  useEffect(() => {
    setLoading(true);

    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }aliado/paqueteById?id=${paqueteId}`,
        userHeaders(false)
      )
      .then((res) => {
        const { packageInfo, aliado } = res.data;
        document.title = 'Planodi | ' + packageInfo.name;
        setInfoAliado(aliado);
        setInfoPaquete(packageInfo);
        setImages(packageInfo.multimediaPackages.map((item) => item.url));
        setLoading(false);
      })
      .catch(() => {
        navigate(t('routes.explore'));
      });
  }, []);

  return (
    <div className="paquetes-page-wrp-all">
      {loading ? null : mobile2 && document.referrer !== '' ? (
        <>
          <div className="paquetes-page-top-nav-mobile">
            <div className="paquetes-page-mobile-button">
              <p
                className="paquetes-page-mobile-button-p"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <span>
                  <IoIosArrowBack style={{ fontSize: '1rem', marginBottom: '3px' }} />
                </span>
                {t('shared.back')}
              </p>
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              zIndex: -100,
              opacity: 0,
              pointerEvents: 'none',
            }}
          >
            <Navbar type="black" />
          </div>
        </>
      ) : (
        <Navbar type="black" />
      )}
      {loading ? (
        <DotLoading />
      ) : (
        <>
          <GalleryTemplate2
            t={t}
            showGallery={showGallery}
            setShowGallery={setShowGallery}
            imagenActualGal={activeImage}
            imagenes={images}
            changeImage={changeImage}
          />
          <div className="paquetes-page-wrp">
            <div className="paquetes-page-content">
              {mobile ? (
                <div style={{ position: 'relative', width: '100%' }}>
                  <div className="paquetes-page-gall-mobile-counter-img">
                    <p>
                      {activeScrollImage} / {images.length}
                    </p>
                  </div>

                  <div
                    className="paquetes-page-gall-mobile-wrp"
                    ref={refFLexImages}
                    onScroll={handleScrollImage}
                  >
                    <div
                      className="paquetes-page-gall-mobile-flex"
                      style={{ width: `${100 * images.length}%` }}
                    >
                      {images.map((item, idx) => (
                        <img
                          className="paquetes-page-gall-mobile-img"
                          style={{ background: `url(${images[idx]})` }}
                          key={idx}
                          alt=""
                          onClick={() => setShowGallery(true)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="paquetes-page-images-wrp">
                  {images
                    .filter((item, idx) => idx < 6)
                    .map((img, idx) => (
                      <div
                        className={`paquetes-page-image ${
                          idx === activeImage ? 'paquete-page-main-image' : null
                        }`}
                        style={{ background: `url(${img}) #d0d0d0` }}
                        key={img}
                        onMouseEnter={() => {
                          setActiveImage(idx);
                        }}
                        onMouseLeave={() => {
                          setActiveImage(0);
                        }}
                        onClick={() => setShowGallery(true)}
                      >
                        <div
                          className={`paquetes-page-image-gall-btn ${
                            idx === activeImage && activeImage !== 0 ? 'active' : null
                          }`}
                        >
                          {t('packagePage.fullGallery')}
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <div className="paquetes-page-info">
                <div className="paquetes-page-info-text">
                  <div>
                    <p className="paquetes-page-info-text-titl">{infoPaquete.name}</p>
                    <p className="paquetes-page-info-text-subtitl">
                      {t('packagePage.from')}{' '}
                      {mobile2 ? (
                        <a href={`${t('routes.profile')}/${infoAliado.pathUrl}`}>
                          {infoAliado.nombre}
                        </a>
                      ) : (
                        <a
                          href={`${t('routes.profile')}/${infoAliado.pathUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {infoAliado.nombre}
                        </a>
                      )}
                    </p>
                    {infoAliado.verificado === 1 && (
                      <p className="paquetes-page-info-text-subtitl" style={{ fontSize: '14px' }}>
                        <MdVerifiedUser
                          style={{
                            color: '#8c50ff',
                            fontSize: '1rem',
                            margin: '0 6px 2px 0',
                          }}
                        />
                        {t('packagePage.verified')}
                      </p>
                    )}
                    <p className="paquetes-page-info-text-subtitl" style={{ fontSize: '14px' }}>
                      {infoAliado.relationAliadoLocation
                        .map((i) => i.staticLocation.name)
                        .join(', ')}
                    </p>
                  </div>
                  {Boolean(infoPaquete.descuento) ||
                    (Boolean(infoAliado.descuento) && (
                      <div className="temp2-general-inf-wrp">
                        <Alert severity="success" icon={<PlanodiIcons name="discount" />}>
                          {t('shared.discount', {
                            discount: infoPaquete.descuento
                              ? infoPaquete.descuento
                              : infoAliado.descuento,
                          })}
                        </Alert>
                      </div>
                    ))}
                  {mobile2 && <BoxPaquetePage infoPaquete={infoPaquete} infoAliado={infoAliado} />}

                  <div style={{ paddingTop: '1.8rem' }}>
                    <RichTextVisualizer content={infoPaquete.packageContent} />
                  </div>
                </div>
                {!mobile2 && <BoxPaquetePage infoPaquete={infoPaquete} infoAliado={infoAliado} />}
              </div>
            </div>
          </div>
        </>
      )}
      {loading ? null : <Footer />}
    </div>
  );
}

