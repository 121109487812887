import React, { useEffect, useState } from 'react';
import RichText from '../../../componentes/RichText/RichText';
import { useTranslation } from 'react-i18next';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { botonesAtrasYAdelante } from '../../../componentes/pasos/PasoForm';
import { useParams } from 'react-router-dom';
import Toggle from '../../../componentes/Toggle/Toggle';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import MyCheckbox from '../../../componentes/formikInputs/MyCheckbox/MyCheckbox';
import PoolIcon from '@material-ui/icons/Pool';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import MyTextField from '../../../componentes/formikInputs/MyTextField/MyTextField';
import MyGoogleDirection from '../../../componentes/formikInputs/MyGoogleDirection/MyGoogleDirection';
import axios from 'axios';
import useSaveInfoGeneralTerrazas from './useSaveInfoGeneralTerrazas';

const EditInfo = ({ content, idAliado, dataSecundaria }) => {
  const { t } = useTranslation('global');
  const { pathName } = useParams();
  const mobile = useMediaQuery('(max-width:960px)');
  const buttonTypes = botonesAtrasYAdelante();
  const [newContent, setNewContent] = useState(content);
  const { mutate: saveInfoTerraza, isLoading } = useSaveInfoGeneralTerrazas(pathName);
  const [googleAutocomplete, setGoogleAutocomplete] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }aliado/googleConfig`
      )
      .then((res) => {
        if (res.data.google_places_autocomplete) {
          setGoogleAutocomplete(true);
        }
      });
  }, []);

  const initialValuesTerraza = {
    capacidad: dataSecundaria?.capacidad?.split('-')[0],
    capacidad2: dataSecundaria?.capacidad?.split('-')[1],
    direccion: dataSecundaria?.direccion,
    alcohol: dataSecundaria?.alcohol,
    eventosDia: dataSecundaria?.eventosDia,
    eventosNoche: dataSecundaria?.eventosNoche,
    estacionamiento: dataSecundaria?.estacionamiento,
    alberca: dataSecundaria?.alberca,
  };
  const validationSchemaTerraza = Yup.object({
    capacidad: Yup.number()
      .typeError(t('yup.number'))
      .required(t('yup.requiredContinue'))
      .min(0, t('yup.numberPositive')),
    capacidad2: Yup.number()
      .typeError(t('yup.number'))
      .required(t('yup.requiredContinue'))
      .moreThan(Yup.ref('capacidad'), t('yup.largerNumber')),
    direccion: Yup.string()
      .required(t('yup.requiredContinue'))
      .max(190, t('yup.addressMaxLength', { amount: 190 })),
    alcohol: Yup.bool(),
    eventosDia: Yup.bool(),
    eventosNoche: Yup.bool(),
    estacionamiento: Yup.bool(),
    alberca: Yup.bool(),
  });
  return (
    <>
      <RichText initialValue={content} setNewContent={(val) => setNewContent(val)} />
      <Formik
        initialValues={initialValuesTerraza}
        onSubmit={({
          capacidad,
          capacidad2,
          direccion,
          alcohol,
          eventosDia,
          eventosNoche,
          estacionamiento,
          alberca,
        }) => {
          const capacidadRango = `${capacidad}-${capacidad2}`;
          saveInfoTerraza({
            idAliado,
            newContent,
            capacidad: capacidadRango,
            direccion,
            alcohol,
            eventosDia,
            eventosNoche,
            estacionamiento,
            alberca,
          });
        }}
        validationSchema={validationSchemaTerraza}
        component={({ errors }) => (
          <FormikForm style={{ width: '100%' }}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                className="postion-rel"
                style={{ padding: '0', marginTop: '2rem' }}
              >
                <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
                  {t('afiliadosSteps.details.capacity')}{' '}
                  <span className="alaido-form-label2">
                    {t('afiliadosSteps.details.capacity2')}
                  </span>
                </p>
                <MyTextField
                  name="capacidad"
                  placeholder={`${t('afiliadosSteps.details.abbreviation')} 50`}
                  style={{
                    width: '45%',
                    float: 'left',
                    margin: '0',
                  }}
                />
                <p
                  style={{
                    float: 'left',
                    margin: '1rem 0rem 0 0',
                    width: '10%',
                    textAlign: 'center',
                  }}
                >
                  -
                </p>
                <MyTextField
                  name="capacidad2"
                  placeholder={`${t('afiliadosSteps.details.abbreviation')} 100`}
                  style={{ width: '45%', float: 'left', margin: '0' }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="postion-rel"
                style={{ padding: '0', marginTop: '2rem' }}
              >
                <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
                  {t('afiliadosSteps.details.address')}
                  {'  '}
                  <span className="alaido-form-label2">{t('afiliadosSteps.details.address2')}</span>
                </p>

                <MyGoogleDirection
                  name="direccion"
                  placeholder={t('afiliadosSteps.details.addressExample')}
                  style={{ width: '100%' }}
                  googleAutocomplete={googleAutocomplete}
                />
              </Grid>
              <Grid item xs={12} className="postion-rel" style={{ marginTop: '2rem' }}>
                <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
                  {t('afiliadosSteps.details.options')}
                </p>
              </Grid>
              <Grid item>
                <MyCheckbox name="alcohol" isValid>
                  <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="alcohol">
                    <LocalBarIcon />
                    {t('afiliadosSteps.details.opt_alcohol')}
                  </label>
                </MyCheckbox>
              </Grid>
              <Grid item>
                <MyCheckbox name="eventosDia" isValid>
                  <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="eventosDia">
                    <Brightness5Icon />
                    {t('afiliadosSteps.details.opt_day')}
                  </label>
                </MyCheckbox>
              </Grid>
              <Grid item>
                <MyCheckbox name="eventosNoche" isValid>
                  <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="eventosNoche">
                    <Brightness2Icon />
                    {t('afiliadosSteps.details.opt_night')}
                  </label>
                </MyCheckbox>
              </Grid>
              <Grid item>
                <MyCheckbox name="estacionamiento" isValid>
                  <label
                    style={{ display: 'inline', marginLeft: '0.5rem' }}
                    htmlFor="estacionamiento"
                  >
                    <DriveEtaIcon />
                    {t('afiliadosSteps.details.opt_parking')}
                  </label>
                </MyCheckbox>
              </Grid>
              <Grid item>
                <MyCheckbox name="alberca" isValid>
                  <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="alberca">
                    <PoolIcon />
                    {t('afiliadosSteps.details.opt_pool')}
                  </label>
                </MyCheckbox>
              </Grid>

              <div
                style={
                  mobile
                    ? {
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '1.2rem',
                      }
                    : { width: '100%', textAlign: 'right', marginTop: '1.1rem' }
                }
              >
                <Button
                  type="submit"
                  className={buttonTypes.saveButton}
                  style={
                    mobile
                      ? { width: '100%', marginTop: '1.5rem' }
                      : { width: '30%', marginLeft: '1.5rem' }
                  }
                  disabled={isLoading || Boolean(Object.keys(errors).length)}
                >
                  {t('shared.save')}
                </Button>
              </div>
              {Object.keys(errors).length > 0 ? (
                <p
                  style={{
                    color: '#f44336',
                    fontFamily: 'Roboto',
                    marginTop: '0.5rem',
                    fontSize: '14px',
                  }}
                >
                  {t('yup.incomplete')}
                </p>
              ) : null}
            </Grid>
          </FormikForm>
        )}
      />
    </>
  );
};

function EditarInfoGeneralTerrazas({
  content,
  isSelected,
  setIsSelected,
  isCollapsible,
  idAliado,
  dataSecundaria,
}) {
  const { t } = useTranslation('global');
  return (
    <>
      {isCollapsible ? (
        <Toggle
          handleOnSelect={() => setIsSelected(4)}
          text={t('afiliadosSteps.details.title')}
          isSelected={isSelected}
        >
          <EditInfo idAliado={idAliado} content={content} dataSecundaria={dataSecundaria} />
        </Toggle>
      ) : (
        <>
          <p className={'url-txt-instruc-ed-neg'}>{t('afiliadosSteps.details.title')}</p>
          <EditInfo idAliado={idAliado} content={content} dataSecundaria={dataSecundaria} />
        </>
      )}
    </>
  );
}

export default EditarInfoGeneralTerrazas;

